import React from 'react';
import classes from './Radio.module.scss'

const Radio = ( props ) => {

    let cls = [classes.container];

    if(props.type === 'quantity'){
        cls.push('quantity')
    }
    return (
        <React.Fragment>
            <label className = {cls.join(' ')} >{props.value}
              <input
                  onChange={props.changed}
                  type = "radio"
                  name = {props.name}
                  value = {props.value}
                  checked={props.isSelected}
              />
              <span className = {classes.checkmark}></span>
            </label>
        </React.Fragment>
    )
};

export default Radio;