import React, { useEffect, useState } from 'react';
import Error from "../../../Error/Error";
import classes from "../../Gender/Gender.module.scss";
import Radio from "../../../UI/Radio/Radio";
import {  setLongTermReturnsSpouse, setLongTermReturns } from "../../../../store/actions/investorProfileActions";
import { connect } from "react-redux";

const LongTermReturnsSpouseComp = ( props ) => {
    const [checked, setChecked] = useState('');
    const [error, setError] = useState(false);
    const fieldName = 'longTermReturnsSpouse';

    const radios = [
        {
            value: 'Yes'
        },
        {
            value: 'No'
        }
    ];

    const changeValHandler = (e) => {
        const val = e.target.value;
        setChecked(val);
        props.setLongTermReturnsSpouse(val);
        props.setLongTermReturns();
        setError(false)
    };


    useEffect(() => {
        setChecked(props.longTermReturnsSpouse);
        setError(!props.longTermReturnsSpouse)
    }, [props.longTermReturnsSpouse, error]);

    return (
        <React.Fragment>
            {
                error &&
                <Error
                    message='Selection required'
                    style={{
                        left:'50%',
                        transform: 'translateX(-50%)',
                        marginLeft:0
                    }}
                />
            }

            {
                radios.map((radio, index) => {
                    return (
                        <div key={index} className = {classes.ContentRow__card__radio}>
                             <Radio
                                 changed={changeValHandler}
                                 name={fieldName}
                                 value={radio.value}
                                 isSelected={ checked === radio.value }
                             />
                         </div>
                    )
                })
            }
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        longTermReturnsSpouse: state.investorProfile.longTermReturnsSpouse
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLongTermReturnsSpouse: (val)=> dispatch(setLongTermReturnsSpouse(val)),
        setLongTermReturns: ()=> dispatch(setLongTermReturns())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LongTermReturnsSpouseComp);