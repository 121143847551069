import React, { useEffect } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import classes from "./shortTermLosses/shortTermLosses.module.scss";
import Navigation from "../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import LongTermReturnsComp from "../../../components/ContentRow/Step2/LongTermReturns/LongTermReturnsComp";
import LongTermReturnsSpouseComp
    from "../../../components/ContentRow/Step2/LongTermReturns/LongTermReturnsSpouseComp";
import InnerLayoutStepContent from "../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import InnerLayoutStep from "../../../hoc/InnerLayoutStep/InnerLayoutStep";
import { secondStepCompleted } from "../../../store/actions/investorProfileActions";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const LongTermReturns = ( props ) => {
    const titles = {
        layoutTitle: 'YOUR INVESTOR PROFILE',
        sectionTitle: 'Are you willing to experience some extended periods of volatility for potential higher long-term returns?',
    };

    const title = "Retirement planning, market volatility and higher returns";

    const next = (props.longTermReturnsYou === "Yes" || props.longTermReturnsSpouse === 'Yes')
        ? retirementCalcLinks.recoveryPeriod
        : retirementCalcLinks.guaranteedFee;

    useEffect(() => {
        props.secondStepCompleted(false)
    }, [props]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep2={(2/9)*100}
            active={'step2'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='longTermReturns'
                sectionTitle={titles.sectionTitle}
            >

                {
                    props.shortTermLossesYou === 'Yes' &&
                    <InnerLayoutStepContent type={'you'}>
                        <div className={classes.RadioWrap}>
                            <LongTermReturnsComp/>
                        </div>
                    </InnerLayoutStepContent>
                }

                {
                    props.isSpouse && props.shortTermLossesSpouse === 'Yes' &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <div className={classes.RadioWrap}>
                            <LongTermReturnsSpouseComp/>
                        </div>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={props.isCompletedLongTermReturns}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        isCompletedLongTermReturns: state.investorProfile.isCompletedLongTermReturns,
        longTermReturnsYou: state.investorProfile.longTermReturnsYou,
        longTermReturnsSpouse: state.investorProfile.longTermReturnsSpouse,
        shortTermLossesSpouse: state.investorProfile.shortTermLossesSpouse,
        shortTermLossesYou: state.investorProfile.shortTermLossesYou
    }
}

function mapDispatchToProps(dispatch) {
    return {
        secondStepCompleted: (val) => dispatch(secondStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LongTermReturns);